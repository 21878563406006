
import './App.css';
import { SocialIcon } from 'react-social-icons';
import Whitelist from './lib/Whitelist';
import comWhitelist from './lib/comWhitelist';
import React, { Component } from 'react';  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {validateWallet: ''};
    this.state = {rawProof: ''};

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      validateWallet: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const expression = /^0x[a-fA-F0-9]{40}$/g;
    var validWallet = expression.test(String(this.state.validateWallet).toLowerCase());
    var proof = Whitelist.getRawProofForAddress(this.state.validateWallet);

    this.setState({
      rawProof: proof
    });

    if (validWallet) {
      if (comWhitelist.contains(this.state.validateWallet)) {

        toast.success(<>
          Congratulations! You are <strong>on our Community Allowlist </strong>!          
        </>,  {
          position: "bottom-center", 
          autoClose: 2000,}
          );
      }
      else if (Whitelist.contains(this.state.validateWallet)) {
        toast.success(<>
          Congratulations! You are <strong>on our Allowlist</strong>!          
        </>,  {
          position: "bottom-center", 
          autoClose: 2000,}
          );
      }
      else {
        toast.error(<>
          Sorry mate, you are <strong>NOT</strong> on our Allowlist.
        </>,  {
          position: "bottom-center", 
          autoClose: 2000,}
          );
      }
    }
    else {
      toast.error(<>
        Please enter a <strong>Valid</strong> ETH Wallet.
      </>,  {
          position: "bottom-center", 
          autoClose: 2000,}
          );
    }

  }


  render() {
    moment.tz.setDefault("Asia/Singapore");
    var date = moment("20230116, 17,01,0", "YYYYMMDD, h:mm:ss").fromNow();
    return (
      <div className="App">
        <header className="App-header">
          <img src='./img/ATG_Logo_Long.png' className="atg-logo" alt="atg_logo" />
          
          <br></br>
          <div style={{color: 'black', fontFamily: 'acumin-pro-wide', fontWeight: '800'}}>Last Updated: {date}</div>
          <div className="walletCheck">
            <input name="wallet" id="walletInput" onChange={this.handleInputChange} value={this.state.validateWallet} placeholder="Paste your address here"></input>
            <button className="primary" type="submit" value="Check" onClick={this.handleSubmit}> <span><a href="#"></a></span></button>
          </div>
          <div className="side">
            <SocialIcon className="my-social-icon" url='https://twitter.com/ArtTokyoGlobal' color="currentColor" network='twitter' 
            style={{ height: 40, width: 40 }}/>
              &nsbp
              <SocialIcon className="my-social-icon" url='https://discord.gg/arttokyoglobal' color="currentColor" network='discord' 
              style={{ height: 40, width: 40 }}/>
            </div>
        </header>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
