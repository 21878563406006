
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import {Buffer} from 'buffer';
import NodeRSA from 'node-rsa';
import data from '../lib/encrypted-2.json';

//import check from '../lib/check.json';
//import whitelistAddresses from '../../../../smart-contract/config/whitelist.json';

const key = new NodeRSA({b: 512});
const pKey = process.env.REACT_APP_WHITELIST_PRIVATE_KEY;
const mKey = process.env.REACT_APP_PUBLIC_KEY 

key.importKey(pKey!, 'pkcs8-pem');
key.importKey(mKey!, 'pkcs8-public-pem');

key.setOptions({ encryptionScheme: 'pkcs1' });

//console.log(key.exportKey('pkcs8-public-pem'));
//console.log(process.env.REACT_APP_PUBLIC_KEY);
//const encrypted = key.encryptPrivate(check, 'base64');
//console.log(encrypted);
const decrypted = key.decryptPublic(data[0], 'utf8');
const whitelistAddresses = JSON.parse(decrypted);
//console.log(whitelistAddresses);

export default new class Whitelist { 
  
  private merkleTree!: MerkleTree;


  private getMerkleTree(): MerkleTree
  {
    if (this.merkleTree === undefined) {
      const leafNodes = whitelistAddresses.map((addr: string | number | Buffer | import("bn.js")) => keccak256(addr));
      
      this.merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    }

    return this.merkleTree;
  }

  public getProofForAddress(address: string): string[]
  {
    return this.getMerkleTree().getHexProof(keccak256(address));
  }

  public getRawProofForAddress(address: string): string
  {
    return this.getProofForAddress(address).toString().replaceAll('\'', '').replaceAll(' ', '');
  }

  public contains(address: string): boolean
  {
    return this.getMerkleTree().getLeafIndex(Buffer.from(keccak256(address))) >= 0;
  }
};